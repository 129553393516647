import type { ProjectData } from "types/app.types";

import hinov00 from "assets/images/projects/hinov/hinov-00.jpg";
import hinov01 from "assets/images/projects/hinov/hinov-01.jpg";
import hinov02 from "assets/images/projects/hinov/hinov-02.jpg";
import hinov03 from "assets/images/projects/hinov/hinov-03.jpg";
import hinov04 from "assets/images/projects/hinov/hinov-04.jpg";
import hinov05 from "assets/images/projects/hinov/hinov-05.jpg";
import hinov06 from "assets/images/projects/hinov/hinov-06.jpg";
import hinov07 from "assets/images/projects/hinov/hinov-07.jpg";
import hinov08 from "assets/images/projects/hinov/hinov-08.jpg";
import hinov09 from "assets/images/projects/hinov/hinov-09.jpg";
import hinov10 from "assets/images/projects/hinov/hinov-10.jpg";
import hinov11 from "assets/images/projects/hinov/hinov-11.jpg";
import hinov12 from "assets/images/projects/hinov/hinov-12.jpg";
import hinov13 from "assets/images/projects/hinov/hinov-13.jpg";
import hinov14 from "assets/images/projects/hinov/hinov-14.jpg";
import hinov15 from "assets/images/projects/hinov/hinov-15.jpg";
import hinov16 from "assets/images/projects/hinov/hinov-16.jpg";
import hinov17 from "assets/images/projects/hinov/hinov-17.jpg";
import hinov18 from "assets/images/projects/hinov/hinov-18.jpg";
import hinov19 from "assets/images/projects/hinov/hinov-19.jpg";
import hinov20 from "assets/images/projects/hinov/hinov-20.jpg";
import hinov21 from "assets/images/projects/hinov/hinov-21.jpg";
import hinov22 from "assets/images/projects/hinov/hinov-22.jpg";
import hinov23 from "assets/images/projects/hinov/hinov-23.jpg";
import hinov24 from "assets/images/projects/hinov/hinov-24.jpg";
import hinov25 from "assets/images/projects/hinov/hinov-25.jpg";
import hinov26 from "assets/images/projects/hinov/hinov-26.jpg";
import hinov27 from "assets/images/projects/hinov/hinov-27.jpg";
import hinov28 from "assets/images/projects/hinov/hinov-28.jpg";
import hinov29 from "assets/images/projects/hinov/hinov-29.jpg";
import hinov30 from "assets/images/projects/hinov/hinov-30.jpg";
import hinov31 from "assets/images/projects/hinov/hinov-31.jpg";
import hinov32 from "assets/images/projects/hinov/hinov-32.jpg";
import hinov33 from "assets/images/projects/hinov/hinov-33.jpg";
import hinov34 from "assets/images/projects/hinov/hinov-34.jpg";
import hinov35 from "assets/images/projects/hinov/hinov-35.jpg";
import hinov36 from "assets/images/projects/hinov/hinov-36.jpg";
import hinov37 from "assets/images/projects/hinov/hinov-37.jpg";
import hinov38 from "assets/images/projects/hinov/hinov-38.jpg";
import hinov39 from "assets/images/projects/hinov/hinov-39.jpg";
import hinov40 from "assets/images/projects/hinov/hinov-40.jpg";
import hinov41 from "assets/images/projects/hinov/hinov-41.jpg";
import hinov42 from "assets/images/projects/hinov/hinov-42.jpg";
import hinov43 from "assets/images/projects/hinov/hinov-43.jpg";

const project: ProjectData = {
  id: "hinov",
  title: "Bureau Hinov",
  attributes: [
    ["lieu", "Noisy-le-Grand"],
    ["surface", "25 m²"],
    ["programme", "Création d’un bureau"],
    ["mission", "Mission complète, conception et maîtrise d’œuvre, suivi de travaux"],
    ["statut", "Livré en juin 2022"],
    ["photos", "Agathe Tissier"],
  ],
  description: `Le bureau d’Hinov est une extension d’une maison de ville.

Il a été imaginé, à l’abri des regards, ouvert sur le jardin intérieur et n’est pas visible depuis la rue.

Il traduit la volonté de créer une architecture moderne en façade brique et toiture en ardoise qui s’intègre avec harmonie dans le quartier pavillonnaire.

L’ancien garage a été démoli afin de recréer entièrement ce nouveau volume, qui se détache de la maison grâce à l’aménagement d’une terrasse extérieur en mezzanine.

Le bureau est tourné vers le jardin avec sa grande baie vitrée de quatre mètres de haut qui offre un espace lumineux tout au long de la journée.

De l’extérieur, les suspensions lumineuses tombent avec poésie le long de la façade.

Le choix de mobiliers vintage et patinés donne un côté authentique et unique au bureau dans l’esprit du concept japonais « wabi-sabi » qui célèbre l’imperfection des choses par la beauté du temps qui passe.

Une grande partie de la décoration est également chinée dans les brocantes et contribue à cette touche personnelle.

Le parquet effet vieilli au rez-de-chaussée renforce cette atmosphère.

En opposition, l’agencement en contreplaqué de peuplier réalisé sur mesure apporte de la modernité et un côté plus minimaliste par l’utilisation du bois clair et de formes simples.`,
  imageSrc: hinov00,
  slides: [
    [hinov01],
    [hinov02, hinov03],
    [hinov04, hinov05, hinov06],
    [hinov07, hinov08],
    [hinov09],
    [hinov10, hinov11, hinov12],
    [hinov13, hinov14],
    [hinov15],
    [hinov16, hinov17],
    [hinov18],
    [hinov19, hinov20],
    [hinov21, hinov22],
    [hinov23, hinov24],
    [hinov25],
    [hinov26, hinov27],
    [hinov28, hinov29],
    [hinov30, hinov31],
    [hinov32, hinov33],
    [hinov34, hinov35],
    [hinov36, hinov37],
    [hinov38, hinov39],
    [hinov40, hinov41],
    [hinov42, hinov43],
  ],
};

export default project;
