import type { ProjectData } from "types/app.types";

import nestayleshalles00 from "assets/images/projects/nestayleshalles/nestayleshalles-00.jpg";
import nestayleshalles01 from "assets/images/projects/nestayleshalles/nestayleshalles-01.jpg";
import nestayleshalles02 from "assets/images/projects/nestayleshalles/nestayleshalles-02.jpg";
import nestayleshalles03 from "assets/images/projects/nestayleshalles/nestayleshalles-03.jpg";
import nestayleshalles04 from "assets/images/projects/nestayleshalles/nestayleshalles-04.jpg";
import nestayleshalles05 from "assets/images/projects/nestayleshalles/nestayleshalles-05.jpg";
import nestayleshalles06 from "assets/images/projects/nestayleshalles/nestayleshalles-06.jpg";
import nestayleshalles07 from "assets/images/projects/nestayleshalles/nestayleshalles-07.jpg";
import nestayleshalles08 from "assets/images/projects/nestayleshalles/nestayleshalles-08.jpg";
import nestayleshalles09 from "assets/images/projects/nestayleshalles/nestayleshalles-09.jpg";
import nestayleshalles10 from "assets/images/projects/nestayleshalles/nestayleshalles-10.jpg";
import nestayleshalles11 from "assets/images/projects/nestayleshalles/nestayleshalles-11.jpg";
import nestayleshalles12 from "assets/images/projects/nestayleshalles/nestayleshalles-12.jpg";
import nestayleshalles13 from "assets/images/projects/nestayleshalles/nestayleshalles-13.jpg";
import nestayleshalles14 from "assets/images/projects/nestayleshalles/nestayleshalles-14.jpg";
import nestayleshalles15 from "assets/images/projects/nestayleshalles/nestayleshalles-15.jpg";
import nestayleshalles16 from "assets/images/projects/nestayleshalles/nestayleshalles-16.jpg";
import nestayleshalles17 from "assets/images/projects/nestayleshalles/nestayleshalles-17.jpg";
import nestayleshalles18 from "assets/images/projects/nestayleshalles/nestayleshalles-18.jpg";
import nestayleshalles19 from "assets/images/projects/nestayleshalles/nestayleshalles-19.jpg";
import nestayleshalles20 from "assets/images/projects/nestayleshalles/nestayleshalles-20.jpg";
import nestayleshalles21 from "assets/images/projects/nestayleshalles/nestayleshalles-21.jpg";
import nestayleshalles22 from "assets/images/projects/nestayleshalles/nestayleshalles-22.jpg";
import nestayleshalles23 from "assets/images/projects/nestayleshalles/nestayleshalles-23.jpg";
import nestayleshalles24 from "assets/images/projects/nestayleshalles/nestayleshalles-24.jpg";
import nestayleshalles25 from "assets/images/projects/nestayleshalles/nestayleshalles-25.jpg";
import nestayleshalles26 from "assets/images/projects/nestayleshalles/nestayleshalles-26.jpg";
import nestayleshalles27 from "assets/images/projects/nestayleshalles/nestayleshalles-27.jpg";
import nestayleshalles28 from "assets/images/projects/nestayleshalles/nestayleshalles-28.jpg";
import nestayleshalles29 from "assets/images/projects/nestayleshalles/nestayleshalles-29.jpg";
import nestayleshalles30 from "assets/images/projects/nestayleshalles/nestayleshalles-30.jpg";
import nestayleshalles31 from "assets/images/projects/nestayleshalles/nestayleshalles-31.jpg";
import nestayleshalles32 from "assets/images/projects/nestayleshalles/nestayleshalles-32.jpg";
import nestayleshalles33 from "assets/images/projects/nestayleshalles/nestayleshalles-33.jpg";

const project: ProjectData = {
  id: "nestayleshalles",
  title: "Hotel Nestay les Halles",
  attributes: [
    ["lieu", "Paris 1er"],
    ["surface", "586 m²"],
    ["programme", "Rénovation complète d’un hôtel parisien de 16 chambres"],
    ["mission", "Mission complète conception et maîtrise d’œuvre, suivi de chantier"],
    ["statut", "Livré en septembre 2024"],
    ["photos", "Stéphane Bozin"],
  ],
  description: `Le deuxième hôtel de la chaîne hôtelière Nestay a vu jour peu de temps après celui de Sentier et offre 16 suites haut de gamme.

L’immeuble haussmannien en pierre de taille a été entièrement restauré par un nettoyage de ses façades et la réfection de sa couverture en zinc. 
Le projet a créé 9 lucarnes en bâtière sur le toit situées dans l'axe des baies existantes aux étages inférieures. 

Tout comme l’hôtel Nestay Sentier, chaque suite possède son identité et offre une décoration élégante et soignée.
`,
  imageSrc: nestayleshalles00,
  slides: [
    [nestayleshalles01],
    [nestayleshalles02],
    [nestayleshalles03, nestayleshalles04],
    [nestayleshalles05],
    [nestayleshalles06, nestayleshalles07],
    [nestayleshalles08],
    [nestayleshalles09, nestayleshalles10],
    [nestayleshalles11],
    [nestayleshalles12, nestayleshalles13],
    [nestayleshalles14],
    [nestayleshalles15],
    [nestayleshalles16],
    [nestayleshalles17, nestayleshalles18],
    [nestayleshalles19, nestayleshalles20],
    [nestayleshalles21],
    [nestayleshalles22],
    [nestayleshalles23],
    [nestayleshalles24],
    [nestayleshalles25, nestayleshalles26],
    [nestayleshalles27],
    [nestayleshalles28, nestayleshalles29],
    [nestayleshalles30, nestayleshalles31],
    [nestayleshalles32, nestayleshalles33],
  ],
};

export default project;
