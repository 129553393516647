import type { ProjectData } from "types/app.types";

import nestaysentier00 from "assets/images/projects/nestaysentier/nestaysentier-00.jpg";
import nestaysentier01 from "assets/images/projects/nestaysentier/nestaysentier-01.jpg";
import nestaysentier02 from "assets/images/projects/nestaysentier/nestaysentier-02.jpg";
import nestaysentier03 from "assets/images/projects/nestaysentier/nestaysentier-03.jpg";
import nestaysentier04 from "assets/images/projects/nestaysentier/nestaysentier-04.jpg";
import nestaysentier05 from "assets/images/projects/nestaysentier/nestaysentier-05.jpg";
import nestaysentier06 from "assets/images/projects/nestaysentier/nestaysentier-06.jpg";
import nestaysentier07 from "assets/images/projects/nestaysentier/nestaysentier-07.jpg";
import nestaysentier08 from "assets/images/projects/nestaysentier/nestaysentier-08.jpg";
import nestaysentier09 from "assets/images/projects/nestaysentier/nestaysentier-09.jpg";
import nestaysentier10 from "assets/images/projects/nestaysentier/nestaysentier-10.jpg";
import nestaysentier11 from "assets/images/projects/nestaysentier/nestaysentier-11.jpg";
import nestaysentier12 from "assets/images/projects/nestaysentier/nestaysentier-12.jpg";
import nestaysentier13 from "assets/images/projects/nestaysentier/nestaysentier-13.jpg";
import nestaysentier14 from "assets/images/projects/nestaysentier/nestaysentier-14.jpg";
import nestaysentier15 from "assets/images/projects/nestaysentier/nestaysentier-15.jpg";
import nestaysentier16 from "assets/images/projects/nestaysentier/nestaysentier-16.jpg";
import nestaysentier17 from "assets/images/projects/nestaysentier/nestaysentier-17.jpg";
import nestaysentier18 from "assets/images/projects/nestaysentier/nestaysentier-18.jpg";
import nestaysentier19 from "assets/images/projects/nestaysentier/nestaysentier-19.jpg";
import nestaysentier20 from "assets/images/projects/nestaysentier/nestaysentier-20.jpg";
import nestaysentier21 from "assets/images/projects/nestaysentier/nestaysentier-21.jpg";
import nestaysentier22 from "assets/images/projects/nestaysentier/nestaysentier-22.jpg";
import nestaysentier23 from "assets/images/projects/nestaysentier/nestaysentier-23.jpg";
import nestaysentier24 from "assets/images/projects/nestaysentier/nestaysentier-24.jpg";
import nestaysentier25 from "assets/images/projects/nestaysentier/nestaysentier-25.jpg";
import nestaysentier26 from "assets/images/projects/nestaysentier/nestaysentier-26.jpg";
import nestaysentier27 from "assets/images/projects/nestaysentier/nestaysentier-27.jpg";
import nestaysentier28 from "assets/images/projects/nestaysentier/nestaysentier-28.jpg";
import nestaysentier29 from "assets/images/projects/nestaysentier/nestaysentier-29.jpg";
import nestaysentier30 from "assets/images/projects/nestaysentier/nestaysentier-30.jpg";
import nestaysentier31 from "assets/images/projects/nestaysentier/nestaysentier-31.jpg";

const project: ProjectData = {
  id: "nestaysentier",
  title: "Hotel Nestay Sentier",
  attributes: [
    ["lieu", "Paris 2ème"],
    ["surface", "422 m²"],
    ["programme", "Rénovation complète d’un hôtel parisien de 12 chambres "],
    ["mission", "Mission complète conception et maîtrise d’œuvre, suivi de chantier"],
    ["statut", "Livré en septembre 2024"],
    ["photos", "Stéphane Bozin"],
  ],
  description: `L’immeuble date de la première moitié du XIXème siècle et a dû subir une rénovation lourde et structurelle pour la pérennité du bâtiment.

Ancien hôtel économique de 19 petites chambres exiguës, il a été entièrement rénové pour offrir 12 suites haut de gamme de la chaîne hôtelière Nestay.

En plus de la restructuration intérieure, les façades ont été ravalées, la couverture en zinc remplacée et la nouvelle devanture a été créée afin de mettre en valeur le bâtiment et lui redonner tout son cachet.

Chaque suite possède son identité et offre une ambiance à la fois accueillante et raffinée.

Le rez-de-chaussée accueille également un élégant coffee shop attenant à l’hôtel.
`,
  imageSrc: nestaysentier00,
  slides: [
    [nestaysentier01],
    [nestaysentier02],
    [nestaysentier03, nestaysentier04],
    [nestaysentier05, nestaysentier06],
    [nestaysentier07],
    [nestaysentier08],
    [nestaysentier09, nestaysentier10],
    [nestaysentier11],
    [nestaysentier12],
    [nestaysentier13],
    [nestaysentier14, nestaysentier15],
    [nestaysentier16, nestaysentier17],
    [nestaysentier18, nestaysentier19],
    [nestaysentier20, nestaysentier21],
    [nestaysentier22, nestaysentier23],
    [nestaysentier24, nestaysentier25],    
    [nestaysentier26, nestaysentier27],
    [nestaysentier28, nestaysentier29],
    [nestaysentier30, nestaysentier31],
  ],
};

export default project;
