import type { ProjectData } from "types/app.types";

import teamslab01 from "assets/images/projects/teamslab/teams-01.jpg";
import teamslab02 from "assets/images/projects/teamslab/teams-02.jpg";
import teamslab03 from "assets/images/projects/teamslab/teams-03.jpg";
import teamslab04 from "assets/images/projects/teamslab/teams-04.jpg";
import teamslab05 from "assets/images/projects/teamslab/teams-05.jpg";
import teamslab06 from "assets/images/projects/teamslab/teams-06.jpg";
import teamslab07 from "assets/images/projects/teamslab/teams-07.jpg";
import teamslab08 from "assets/images/projects/teamslab/teams-08.jpg";
import teamslab09 from "assets/images/projects/teamslab/teams-09.jpg";
import teamslab10 from "assets/images/projects/teamslab/teams-10.jpg";
import teamslab11 from "assets/images/projects/teamslab/teams-11.jpg";
import teamslab12 from "assets/images/projects/teamslab/teams-12.jpg";
import teamslab13 from "assets/images/projects/teamslab/teams-13.jpg";
import teamslab14 from "assets/images/projects/teamslab/teams-14.jpg";
import teamslab15 from "assets/images/projects/teamslab/teams-15.jpg";
import teamslab16 from "assets/images/projects/teamslab/teams-16.jpg";
import teamslab17 from "assets/images/projects/teamslab/teams-17.jpg";
import teamslab18 from "assets/images/projects/teamslab/teams-18.jpg";
import teamslab19 from "assets/images/projects/teamslab/teams-19.jpg";
import teamslab20 from "assets/images/projects/teamslab/teams-20.jpg";
import teamslab21 from "assets/images/projects/teamslab/teams-21.jpg";
import teamslab22 from "assets/images/projects/teamslab/teams-22.jpg";

const project: ProjectData = {
  id: "teamslab",
  title: "Espace de coworking Teams Lab",
  attributes: [
    ["lieu", "Rueil-Malmaison"],
    ["surface", "158 m²"],
    ["programme", "Création d’un espace de coworking et café"],
    ["mission", "Conception"],
    ["statut", "Livré en février 2023"],
    ["photos", "Camy Duong"],
  ],
  description: `Cet ancien laboratoire désaffecté situé en face de la gare de RER de Rueil-Malmaison, a été rénové afin de créer Teams Lab, un espace de coworking avec des salles de réunion et un café.

Les salles de réunion devaient permettre de créer différentes ambiances de travail par l’aménagement de bureaux mobiles et légers.

Au sous-sol, un espace plus intime permet d’avoir des meetings d’équipe tout en restant dans un cadre chaleureux.

L’accès à l’étage est épuré et sublimé par des bandeaux lumineux en bois qui apporte un côté graphique à l’espace.`,
  imageSrc: teamslab01,
  slides: [
    [teamslab01, teamslab02],
    [teamslab03, teamslab04],
    [teamslab05, teamslab06],
    [teamslab07, teamslab08],
    [teamslab09, teamslab10],
    [teamslab11, teamslab12],
    [teamslab13, teamslab14],
    [teamslab15, teamslab16],
    [teamslab17, teamslab18],
    [teamslab19, teamslab20],
    [teamslab21, teamslab22],
  ],
};

export default project;
