import type { ProjectData } from "types/app.types";

import bacio00 from "assets/images/projects/bacio/bacio-00.jpg";
import bacio01 from "assets/images/projects/bacio/bacio-01.jpg";
import bacio02 from "assets/images/projects/bacio/bacio-02.jpg";
import bacio03 from "assets/images/projects/bacio/bacio-03.jpg";
import bacio04 from "assets/images/projects/bacio/bacio-04.jpg";
import bacio05 from "assets/images/projects/bacio/bacio-05.jpg";
import bacio06 from "assets/images/projects/bacio/bacio-06.jpg";
import bacio07 from "assets/images/projects/bacio/bacio-07.jpg";
import bacio08 from "assets/images/projects/bacio/bacio-08.jpg";
import bacio09 from "assets/images/projects/bacio/bacio-09.jpg";
import bacio10 from "assets/images/projects/bacio/bacio-10.jpg";
import bacio11 from "assets/images/projects/bacio/bacio-11.jpg";
import bacio12 from "assets/images/projects/bacio/bacio-12.jpg";

const project: ProjectData = {
  id: "bacio",
  title: "Restaurant Bacio Terrazza",
  attributes: [
    ["lieu", "Gournay-sur-Marne"],
    ["surface", "243 m²"],
    ["programme", "Rénovation complète d’un restaurant "],
    ["mission", "Conception"],
    ["statut", "Livré en janvier 2024"],
    ["photos", "Made in chino"],
  ],
  description: `Cet ancien restaurant traditionnel familial a subi une transformation complète pour devenir un restaurant italien chaleureux qui attirent les passants des bords de Marne.

Plusieurs ambiances ont été créées afin d’apporter à la fois confort et convivialité.
Une salle toute en longueur mise en valeur par de grandes baies vitrées et où s’adosse une grande banquette en velours couleur rouille.
Des tables rondes donnent vue sur la rivière et lorsque le temps le permet, les grandes baies s’ouvrent totalement pour laisser rentrer la verdure à l’intérieur du restaurant.

Au centre, l’espace sous le bâtiment d’habitation offre plus d’intimité et de calme.
`,
  imageSrc: bacio00,
  slides: [
    [bacio01],
    [bacio02, bacio03],
    [bacio04],
    [bacio05, bacio06],
    [bacio07],
    [bacio08],
    [bacio09, bacio10],
    [bacio11, bacio12],

  ],
};

export default project;
